var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":"","md12":""}},[_c('div',{staticClass:"mb-3"},[_c('page-title',{attrs:{"title":"Projects General Report","useBackBtn":false}})],1),_c('v-card',{staticClass:"col-md-12 mt-2",attrs:{"elevation":"1"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('v-card',{staticClass:"col-md-12",attrs:{"flat":""}},[_c('div',{staticClass:"col-md-12"},[_c('ProjectGauge',{attrs:{"heightData":300,"seriesData":[57]}})],1),_c('h3',{staticClass:"text-dark pa-3"},[_vm._v(" Timeline : "),_c('br'),_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v("January 30,2022 - February 30, 2025")])])])],1),_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('v-card',{attrs:{"flat":""}},[_c('v-chart',{staticClass:"chart col-md-11",attrs:{"option":_vm.option}})],1)],1)])]),_c('v-card',{staticClass:"col-md-12",attrs:{"elevation":"1"}},[_c('v-simple-table',[[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Project")]),_c('th',{staticClass:"text-left"},[_vm._v("Activities")]),_c('th',{staticClass:"text-left"},[_vm._v("Sub-Activities")]),_c('th',{staticClass:"text-left"},[_vm._v("Start Date")]),_c('th',{staticClass:"text-left"},[_vm._v("End Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.reportData),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(" "+_vm._s(item.components)+" "),_c('br'),_c('span',{staticClass:"text-dark"},[_c('v-progress-linear',{attrs:{"color":item.percentage > 60
                      ? 'green'
                      : item.percentage < 45
                      ? 'orange'
                      : 'blue',"buffer-value":100,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.percentage),callback:function ($$v) {_vm.$set(item, "percentage", $$v)},expression:"item.percentage"}})],1)]),_c('td',[_vm._v(" "+_vm._s(item.subComponents)+" "),_c('br'),_c('span',{staticClass:"text-dark"},[_c('v-progress-linear',{attrs:{"color":item.subPercentage > 60
                      ? 'green'
                      : item.subPercentage < 45
                      ? 'orange'
                      : 'blue',"buffer-value":100,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.subPercentage),callback:function ($$v) {_vm.$set(item, "subPercentage", $$v)},expression:"item.subPercentage"}})],1)]),_c('td',[_vm._v(_vm._s(item.startDate))]),_c('td',[_vm._v(_vm._s(item.endDate))]),_c('td',[_c('v-btn',{attrs:{"depressed":"","rounded":"","small":"","link":"","to":"/reports/report-component"}},[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-eye")])],1)],1)])}),0)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }